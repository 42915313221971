import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { SvgIcon } from '@mui/material';
import HomeSmileIcon from 'icons/untitled-ui/duocolor/home-smile';
import LayoutAlt02Icon from 'icons/untitled-ui/duocolor/layout-alt-02';
import { paths } from 'paths';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import HealthAndSafetyTwoToneIcon from '@mui/icons-material/HealthAndSafetyTwoTone';
import ShareIcon from '@mui/icons-material/Share';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import GroupsIcon from '@mui/icons-material/Groups';
import FolderIcon from '@mui/icons-material/Folder';

export interface Item {
    disabled?: boolean;
    external?: boolean;
    icon?: ReactNode;
    items?: Item[];
    label?: ReactNode;
    path?: string;
    title: string;
}

export interface Section {
    items: Item[];
    subheader?: string;
}

// TODO hooks
export const useMenuSections = () => {

    return useMemo(
        () => {
            var standardSections = [
                {
                    items: [
                        {
                            title: 'Home',
                            path: paths.index,
                            icon: (<SvgIcon fontSize="small"><HomeSmileIcon /></SvgIcon>)
                        },
                    ]
                },
                {
                    subheader: 'Protect',
                    items: [
                        {
                            title: 'Assets',
                            path: paths.assets.list,
                            icon: (<SvgIcon fontSize="small"><LayoutAlt02Icon /></SvgIcon>),
                        },
                        {
                            title: 'Projects',
                            path: paths.projects.list,
                            icon: (<SvgIcon fontSize="small"><FolderIcon /></SvgIcon>),
                        },
                        {
                            title: 'Smart Access',
                            path: paths.smartAccess,
                            icon: (<SvgIcon fontSize="small"><ShareIcon /></SvgIcon>),
                        },
                        {
                            title: 'Verify',
                            path: paths.verify,
                            icon: (<SvgIcon fontSize="small"><VerifiedOutlinedIcon /></SvgIcon>),
                        },
                        // {
                        //     title: 'Analytics',
                        //     path: paths.analytics,
                        //     icon: (<SvgIcon fontSize="small"><BarChartIcon /></SvgIcon>),
                        // },
                    ]
                },
                {
                    subheader: 'Settings',
                    items: [
                        {
                            title: 'Account',
                            path: paths.account,
                            icon: (<SvgIcon fontSize="small"><ManageAccountsIcon /></SvgIcon>)
                        },
                        {
                            title: 'Organisation',
                            path: paths.organisation,
                            icon: (<SvgIcon fontSize="small"><GroupsIcon /></SvgIcon>),
                        },
                    ]
                },
                {
                    subheader: 'Quick Actions',
                    items: [
                        {
                            title: 'New Asset',
                            path: paths.assets.new,
                            icon: (<SvgIcon fontSize="small"><HealthAndSafetyTwoToneIcon /></SvgIcon>)
                        },
                    ]
                }




            ];

            return standardSections;
        }, []);

};

