import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { Link, Stack } from '@mui/material';
import { PeerdwebUser } from 'types/users/user';
import DownloadIcon from '@mui/icons-material/Download';
import { CsvBuilder } from 'filefy';
import SplashDisplay from '../splash/splash-display';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { FC, useEffect, useMemo, useState } from 'react';
import PeerdwebService from 'services/peerdweb/peerdweb-service';
import { SmartAccessActivityLog } from 'types/smart-access/smart-access-activity';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { ActivityLogDialog } from './activity-log-dialog';
import { toPrettyDateTime } from 'utils/pretty-pretty';


interface Data {
    id: string;
    createdAt: string;
    log: string;
    user: string;
    recipient: string;
    owner: string;
    txHash: string;
    assetId: string;
    assetName: string;
    obligationHtml: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T, order: Order) {
    if (a[orderBy] == '' || b[orderBy] == '') {
        if (a[orderBy] == b[orderBy]) return 0;
        else {
            if (order == "asc") {
                if (a[orderBy] == '') return -1;
                else return 1;
            } else {
                if (a[orderBy] == '') return 1;
                else return -1;
            }
        }
    }
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy, order)
        : (a, b) => -descendingComparator(a, b, orderBy, order);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    id: keyof Data;
    label: string;
}

const headCells: readonly HeadCell[] = [
    { id: 'createdAt', label: 'Date' },
    { id: 'assetName', label: 'Asset' },
    { id: 'recipient', label: 'Smart Access Recipient' },
    { id: 'log', label: 'Action' },
    { id: 'txHash', label: 'Transaction' },
    { id: 'user', label: 'By' },
];

interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    order: Order;
    orderBy: string;
    hideAssetColumn?: boolean;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort, hideAssetColumn } = props;
    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                {/* thought about coding the heads, but we're *looking* at dynamically choosing columns at some point */}
                {headCells.map((headCell) => {

                    if (headCell.id === 'assetName' && hideAssetColumn)
                        return null;

                    return (
                        <TableCell
                            key={headCell.id} align={'left'} padding={'none'} sortDirection={orderBy === headCell.id ? order : false}
                            sx={{ minWidth: (headCell.id === 'createdAt' ? 200 : 0) }} >
                            <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={createSortHandler(headCell.id)}>
                                {headCell.label}
                            </TableSortLabel>
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
}

function EnhancedTableRow(props: { row?: ReturnType<typeof createData>, link?: string, hideAssetColumn?: boolean, onLogClick?: (log: Data) => void }) {
    const { row, link, onLogClick } = props;

    const createdAtFormatted = row?.createdAt ? `${new Date(row?.createdAt ?? '').toLocaleString('en-GB').split(',')[0]} ${new Date(row?.createdAt ?? '').toLocaleString('en-GB').split(',')[1]}` : null;

    return (
        <TableRow hover tabIndex={-1} key={row?.id}>
            <TableCell>
                {createdAtFormatted || <Skeleton />}
            </TableCell>
            {props.hideAssetColumn ? null :
                <TableCell>
                    <Link href={`/asset/view/${row?.assetId}`}>
                        {row?.assetName || <Skeleton />}
                    </Link>
                </TableCell>
            }
            <TableCell>
                {row != null || <Skeleton />}
                {row?.recipient}
            </TableCell>
            <TableCell>
                {row != null || <Skeleton />}
                <Link component={"button"} onClick={() => onLogClick && row && onLogClick(row!)}>
                    {row?.log}
                </Link>

                <Link href={`/asset/view/${row?.id}`}>

                </Link>
            </TableCell>
            <TableCell>
                {row != null || <Skeleton />}
                {(row?.txHash) &&
                    <>
                        <SplashDisplay hash={row?.txHash} />
                        <Link href={link} target="_blank" rel="noopener noreferrer" sx={{ display: "inline-flex", alignItems: "center", fontSize: "12px" }} >
                            View on Blockchain Explorer <OpenInNewIcon sx={{ ml: 1 }} />
                        </Link>
                    </>
                }
            </TableCell>
            <TableCell>{row?.user || <Skeleton />}</TableCell>
        </TableRow>
    );
}

function createData(
    id: string,
    createdAt: Date,
    log: string,
    user: PeerdwebUser,
    recipient: PeerdwebUser,
    owner: PeerdwebUser,
    txHash: string,
): Data {
    return {
        id,
        createdAt: createdAt.toLocaleDateString(),
        log,
        user: user.displayName,
        recipient: recipient.displayName,
        owner: owner.displayName,
        txHash,
        assetId: 'Asset ID',
        assetName: 'Asset Name',
        obligationHtml: 'Obligation',
    };
}


interface IProps {
    activityLogs: SmartAccessActivityLog[];
    csvFileName: string;
    hideAssetColumn?: boolean;
    isLoading?: boolean;
}

export const ActivityLogsEnhancedTable: FC<IProps> = ({ activityLogs, csvFileName, hideAssetColumn, isLoading }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedLog, setSelectedLog] = useState<Data | null>(null);

    const handleDialogOpen = (log: Data) => {
        setSelectedLog(log);
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
        setSelectedLog(null);
    };

    const peerdwebService = new PeerdwebService();

    const initialRows = activityLogs ? activityLogs.map(log => ({
        id: log.id || '',
        createdAt: new Date(log.createdAt ?? '').toISOString() || '',
        log: log.log || '',
        user: log.user || '',
        recipient: log.recipient || '',
        owner: log.owner || '',
        txHash: log.txHash || '',
        assetId: log.assetId || '',
        assetName: log.assetName || '',
        obligationHtml: log.obligationHtml || ''
    })) : [];

    const [rows, setRows] = useState<Data[]>(initialRows);

    useEffect(() => {
        if (activityLogs) {
            const updatedRows = activityLogs.map(log => ({
                id: log.id || '',
                createdAt: new Date(log.createdAt ?? '').toISOString() || '',
                log: log.log || '',
                user: log.user || '',
                recipient: log.recipient || '',
                owner: log.owner || '',
                txHash: log.txHash || '',
                assetId: log.assetId || '',
                assetName: log.assetName || '',
                obligationHtml: log.obligationHtml || ''
            }));
            setRows(updatedRows);
        }
    }, [activityLogs]);

    const [order, setOrder] = useState<Order>('desc');
    const [orderBy, setOrderBy] = useState<keyof Data>('createdAt');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        try {
            document.querySelector('.MuiTableContainer-root')!.scrollTop = 0;
        } catch (error) {
        }
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        try {
            document.querySelector('.MuiTableContainer-root')!.scrollTop = 0;
        } catch (error) {
        }
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
    const visibleRows = useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage, rows],
    );

    const ExportToCsv = () => {
        const csvBuilder = new CsvBuilder(csvFileName);
        const data = activityLogs?.map(log => {
            return [log?.createdAt || '', log?.recipient || '', log?.log || '', log?.txHash || '', log?.user || '', log?.obligationHtml || ''];
        });
        console.log(data);
        csvBuilder
            .setColumns(['Date', 'Smart Access Recipient', 'Action', 'Transaction', 'By', 'Obligation'])
            .addRows(data || [])
            .exportFile();
    }

    //#region Blockchain Explorer Link - I obviously don't want to do this here, bring the explorer url etc back with the log
    const [txLinks, setTxLinks] = useState<{ [key: string]: string }>({});
    const getBlockchainExplorerLink = async (txHash: string) => {
        var transactionResponse = await peerdwebService.validateSmartAccessTxHash(txHash);
        if (transactionResponse && transactionResponse.data?.explorerUrl)
            return `${transactionResponse.data?.explorerUrl}tx/${txHash}`;
        else
            return '';
    };

    const updateTxLink = async (txHash: string) => {
        const link = await getBlockchainExplorerLink(txHash);
        setTxLinks((prev) => ({ ...prev, [txHash]: link }));
    };

    useEffect(() => {
        if (activityLogs) {
            activityLogs.forEach((log) => {
                if (log.txHash && !txLinks[log.txHash]) {
                    updateTxLink(log.txHash);
                }
            });
        }
    }, [activityLogs]);
    //#endregion


    return (
        <>
            <Stack justifyContent={'space-between'} direction="row" spacing={2} sx={{ py: 1 }}>
                <Typography variant="h4" sx={{ mb: 2 }}>
                    Activity Log
                </Typography>

                <Tooltip title="Export to CSV">
                    <Stack direction={'column'} alignItems="center">
                        <IconButton onClick={ExportToCsv}>
                            <DownloadIcon />
                        </IconButton>
                        <Typography variant="caption">Export to CSV</Typography>
                    </Stack>
                </Tooltip>
            </Stack>


            <Box sx={{ width: '100%' }}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <TableContainer sx={{ width: "100%", height: 600, overflowX: 'auto' }}>
                        <Table stickyHeader sx={{ minWidth: 750 }} aria-labelledby="asset-list" size='small'>
                            <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} hideAssetColumn={hideAssetColumn} />
                            {!isLoading && rows.length === 0 ? (
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={headCells.length}>
                                            <Typography variant="body1" align="center">
                                                No activity
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            ) : (
                                <TableBody>
                                    {isLoading && (
                                        <>
                                            <EnhancedTableRow />
                                            <EnhancedTableRow />
                                            <EnhancedTableRow />
                                            <EnhancedTableRow />
                                            <EnhancedTableRow />
                                            <EnhancedTableRow />
                                        </>
                                    )}
                                    {visibleRows.map((row) => (
                                        <EnhancedTableRow key={row.id} row={row} link={txLinks[row.txHash]} hideAssetColumn={hideAssetColumn} onLogClick={handleDialogOpen} />
                                    ))}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: (49) * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Box>

            <ActivityLogDialog open={openDialog} onClose={handleDialogClose} confirmText={'Close'}>
                {selectedLog && (
                    <Table sx={{ mb: 6 }}>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ maxWidth: 200, minWidth: 80, width: 200 }}>Asset:</TableCell>
                                <TableCell align="left"><strong>{selectedLog.assetName}</strong></TableCell>
                            </TableRow>
                            {selectedLog.owner &&
                                <TableRow>
                                    <TableCell sx={{ maxWidth: 200, minWidth: 80, width: 200 }}>Owner:</TableCell>
                                    <TableCell align="left"><strong>{selectedLog.owner}</strong></TableCell>
                                </TableRow>
                            }
                            {selectedLog.recipient &&
                                <TableRow>
                                    <TableCell sx={{ maxWidth: 200, minWidth: 80, width: 200 }}>Recipient:</TableCell>
                                    <TableCell align="left"><strong>{selectedLog.recipient}</strong></TableCell>
                                </TableRow>
                            }
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ maxWidth: 200, minWidth: 80, width: 200 }}>Action:</TableCell>
                                <TableCell align="left"><strong>{selectedLog.log}</strong></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ maxWidth: 200, minWidth: 80, width: 200 }}>Actioned At:</TableCell>
                                <TableCell align="left"><strong>{toPrettyDateTime(new Date(selectedLog.createdAt))}</strong></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ maxWidth: 200, minWidth: 80, width: 200 }}>Action User:</TableCell>
                                <TableCell align="left"><strong>{selectedLog.user}</strong></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>

                            {selectedLog.obligationHtml &&
                                <TableRow>
                                    <TableCell sx={{ maxWidth: 200, minWidth: 80, width: 200 }}>Obligation:</TableCell>
                                    <TableCell align="left"><strong>{selectedLog.obligationHtml}</strong></TableCell>
                                </TableRow>
                            }
                            {/* <TableRow>
                                <TableCell sx={{ maxWidth: 200, minWidth: 80, width: 200 }}>Transaction:</TableCell>
                                <TableCell align="left"><strong>{selectedLog.txHash}</strong></TableCell>
                            </TableRow> */}
                        </TableBody>
                    </Table>
                )}
            </ActivityLogDialog>
        </>
    );
}
